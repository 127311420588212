<template>
  <div>
    <h1> Kaft Panel</h1>
    <p> Soldaki menü aracılığı ile işlem yapmak istediğiniz sayfalara ulaşabilirsiniz.</p>
   
  </div>
</template>

<script>

export default {
  name: 'Home',
 
}
</script>